import React from 'react'
import Header from '../../../Components/Header/Header'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch, { SwitchProps } from '@mui/material/Switch'
import { styled } from '@mui/material/styles'
import star from '../../../assets/star.png'
import ScrollLinks from '../ScrollLinks'
import reality from '../../../assets/reality.png'
import fiction from '../../../assets/fiction.png'
import threeLine from '../../../assets/rdph1.png'
import threeLine2 from '../../../assets/rdph2.png'
import buttonImg from '../../../assets/Button.svg'
import CustomButton from '../../../Components/Button/Button'
// import AlertDialog from '../../../Components/Dialog/Dialog'
import { useNavigate } from 'react-router-dom'

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 90,
  height: 45,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    color: '#ABFF29',

    '&.Mui-checked': {
      transform: 'translateX(47px)',
      color: '#ABFF29',
      '& + .MuiSwitch-track': {
        backgroundColor:
          theme.palette.mode === 'dark' ? 'transparent' : 'transparent',
        opacity: 1,
        border: '1px solid #DFDFDF',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#ABFF29',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 40,
    height: 40,
  },
  '& .MuiSwitch-track': {
    borderRadius: 100,
    backgroundColor:
      theme.palette.mode === 'light' ? 'transparent' : 'transparent',
    opacity: 1,
    border: '1px solid #DFDFDF',

    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

let links = ['Top', 'Story', 'FAQ']

const Hero = ({ id }) => {
  const navigate = useNavigate()
  const [checked, setChecked] = React.useState(true)

  const handleChange = (event) => {
    setChecked(event.target.checked)
  }

  // const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    navigate('/open-letter#Join us')
    // setOpen(true)
  }

  // const handleClose = () => {
  //   setOpen(false)
  // }

  

  return (
    <div className="heroContainer" id={id}>
      <Header />
      <div className="heroSection">
        <ScrollLinks links={links} />
        <div className="heroContent">
          <div className="heroBox">
            <div className="hrSc">
              <p>telling Web3 stories</p>
              <div className="heroLine">
                <div className="hrSwit">
                  <img src={threeLine} className="hmHrSwImg" alt="" />
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        defaultChecked
                        onChange={handleChange}
                      />
                    }
                  />
                </div>
                <p>In Which</p>
                {checked ? (
                  <img src={fiction} className="rlFcImg" alt="fiction" />
                ) : (
                  // <p className="swaped">
                  //   <strong>FICTION</strong>
                  // </p>
                  // <p className="swaped">
                  //   <strong>REALITY</strong>
                  // </p>
                  <img src={reality} className="rlFcImg" alt="fiction" />
                )}
              </div>
              <p>Is Indistinguishable</p>
              <div className="heroLine">
                <p>From</p>
                {checked ? (
                  <img className="rlFcImg" src={reality} alt="fiction" />
                ) : (
                  // <p className="swaped">
                  //   <strong>FICTION</strong>
                  // </p>
                  // <p className="swaped">
                  //   <strong>REALITY</strong>
                  // </p>
                  <img className="rlFcImg" src={fiction} alt="fiction" />
                )}
                <div className="switchBtn">
                  <img src={buttonImg} className="btnImg" alt="..." />
                  <button className="switchAnimBtn" onClick={handleClickOpen}>
                    <p>
                      {' '}
                      <span> Sign letter</span>
                    </p>
                  </button>
                  <img src={threeLine2} className="hmHrBtnImg" alt="" />
                </div>
              </div>
            </div>
            <div className="mobViewBtn">
              {/* <AlertDialog isOpen={open} handleClose={handleClose} /> */}
              <CustomButton
                isGreen
                text={'Sign Open Letter'}
                onClick={handleClickOpen}
              />
            </div>
            <div className="tipsSec">
              <div className="tipsPara">
                <p>
                  <img src={star} alt="star" /> <span>Tell it–Together</span> is
                  a web3-native network with one mission: to help creators tell
                  enduring stories by giving communities superpowers to spread
                  them!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
