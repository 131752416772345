import React from 'react'
import './button.css'

const CustomButton = ({ isGreen , text,onClick}) => {
  return (
    <>
      {isGreen ? (
        <button className="blLyrTwo" onClick={onClick}>
          <p className=" blackLayerBtn">
            <span className="blMainLyr grnLyrBtn">{text}</span>
          </p>
        </button>
      ) : (
        <button className="blackLayerBtn" onClick={onClick}>
          <p className="blLyrTwo">
            <span className="blMainLyr">{text}</span>
          </p>
        </button>
      )}
    </>
  )
}

export default CustomButton
