import React,{useEffect} from 'react'
import Multiverse from './Multiverse/Multiverse'
import './multiverse.css'
import CardDetail from './CardDetails/CardDetail'
import Footer from '../../Components/Footer/Footer'
import CardDialog from './CardAlert'

const TheMultiverse = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
        <Multiverse />
        <CardDetail />
        <Footer />
    </div>
  )
}

export default TheMultiverse