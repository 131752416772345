import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import line from '../../../assets/LineGrn.png'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
    // console.log(isExpanded, panel)
  }
  return (
    <div className="faqContainer" id="FAQ">
      <div className="fqSec">
        <div className="faqCotent">
          <div className="shadMdGrn"></div>
          <div className="shdSmGrn"></div>
          <div className="shdSmGrey"></div>
          <div className="shadxGrn"></div>
          <div className="faqs">
            <h3 className="stgHead mB20">quick questions</h3>
            <div className="faqList mT20">
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === 'panel1' ? (
                      <RemoveCircleIcon className="rmIcon" />
                    ) : (
                      <AddCircleRoundedIcon className="expIcon" />
                    )
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faqHead">1. summary</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faqPara">
                    Tell it–Together aspires to become a galaxy of IPs in which
                    creators can find unique ways to share “ownership” of a
                    story for entertainment and community building. We call it
                    “gameplay”. Each IP is its subDAO and has a voice in the
                    overall Tell it–Together governance.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <img className="lineImg" src={line} alt="line" />
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === 'panel2' ? (
                      <RemoveCircleIcon className="rmIcon" />
                    ) : (
                      <AddCircleRoundedIcon className="expIcon" />
                    )
                  }
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className="faqHead">
                    2. what is tell-it-together?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faqPara">
                    Tell it–Together is an IP DAO portfolio and an exploration
                    of decentralized entertainment. It is the legal entity that
                    supports fractionalization avoidance. Each IP will have its
                    own unique gameplay and governance. We aim to strike the
                    right balance between progressive decentralization and
                    interoperability. We have designed Tell it-Together to be a
                    positive sum game. We believe a sufficient centralization of
                    the underlying rights is necessary to enter high-production
                    value co-productions, partnerships with third parties
                    companies, and DAOs.
                  </Typography>
                  <br />
                  {/* <br /> */}
                  <Typography className="faqPara mT10">
                    To foster a creator-first and positive-sum ecosystem, we
                    plan to reserve all Tell It-Together governance rights to IP
                    holders. While this will certainly evolve, our hope is that
                    only by buying and participating in the IPs will a party
                    have voting rights. This guarantees that each new IP launch
                    will also be an opportunity to gain governance rights in the
                    Tell it–Together ecosystem.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <img className="lineImg" src={line} alt="line" />
              <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === 'panel3' ? (
                      <RemoveCircleIcon className="rmIcon" />
                    ) : (
                      <AddCircleRoundedIcon className="expIcon" />
                    )
                  }
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className="faqHead">
                    {' '}
                    3. our approach to “IP ownership”
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faqPara">
                    First and foremost, we are creators. Like most creators, we
                    want people to enjoy our stories, for our work to be
                    protected, and for our work to survive us. We live for our
                    characters to inspire and become a meaningful part of the
                    culture. Congress-designated intellectual property and
                    copyright laws provide creators with control over who, how,
                    and where their work can be used, published, or adapted.
                    Eventually, those rights expire (become public domain). It
                    is important to understand that IP ownership has limitations
                    and does not concern just one thing. Copyrights are modular
                    and customizable. Creators can choose to transfer rights
                    one-by-one or in bulk. The truth is that creators have so
                    little leverage in the entertainment industry that we see
                    little innovation. We believe smart contracts and DAOs can
                    change that. For example, NFTs can be vessels for composable
                    rights. Placed in the hands of a community and a network,
                    stories can grow in ways that were never possible before.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <img className="lineImg" src={line} alt="line" />
              <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === 'panel4' ? (
                      <RemoveCircleIcon className="rmIcon" />
                    ) : (
                      <AddCircleRoundedIcon className="expIcon" />
                    )
                  }
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className="faqHead">
                    4. our first IP: Crypto-Z
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faqPara">
                    Crypto-Z is an X-files meets Jurassic Park adventure in
                    which the characters are created by or with the community.
                    The name, Crypto-Z, comes from cryptozoology, the study of
                    unknown or unidentified creatures. Crypto-Z is the first IP
                    of Tell it–Together and at the beginning controls most of
                    the votes over greenlighting the next Tell it–Together
                    original IP. We aim to curate a high-talent density creative
                    community that aligns with our values. Creators are the
                    missing link to bring the next billion users on the chain,
                    so let’s make Crypto-Z a groundbreaking success!
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <img className="lineImg" src={line} alt="line" />
              <Accordion
                expanded={expanded === 'panel5'}
                onChange={handleChange('panel5')}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === 'panel5' ? (
                      <RemoveCircleIcon className="rmIcon" />
                    ) : (
                      <AddCircleRoundedIcon className="expIcon" />
                    )
                  }
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className="faqHead">
                    5. Progressive Decentralization & governance
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faqPara">
                    Voting, governance rights, access, and other utilities
                    depend on each IP. We expect utilities to expand further
                    according to the creator’s vision and the community. We hope
                    to see high-quality, cross-over experiences emerging. We
                    expect a strong multiverse ecosystem will compound the
                    utility of our NFTs over time.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
