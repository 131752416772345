import React from 'react'
import Scrollspy from 'react-scrollspy'

const ScrollLinks = ({ links, hrefTag }) => {
  return (
    <div className="scLinkAbs">
      <div className="linksFixed">
        <Scrollspy
          items={links}

          currentClassName="activeLinkFixed"
        >
          {links.map((val) => (
            <li key={val}>
              <a href={"#"+val}>{val}</a>
            </li>
          ))}
          {/* <li>
            <a href="#story">Story</a>
          </li>
          <li>
            <a href="#faq">FAQ</a>
          </li> */}
        </Scrollspy>
      </div>
    </div>
  )
}

export default ScrollLinks
