
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref } from "firebase/database"

const firebaseConfig = {
    apiKey: "AIzaSyAGkChEuDlUHEbgNHEKPqSqovHqQZO9D-s",
    authDomain: "tell-it-together.firebaseapp.com",
    projectId: "tell-it-together",
    storageBucket: "tell-it-together.appspot.com",
    messagingSenderId: "669126304628",
    appId: "1:669126304628:web:e05351c4f6de4f216ac221",
    measurementId: "G-8LB29SDBCB"
  };


  const app = initializeApp(firebaseConfig);
//   const analytics = getAnalytics(app);

  export const database = getDatabase(app)