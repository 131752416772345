import React,{useEffect} from 'react'
import Footer from '../../Components/Footer/Footer'
import Creator from './Creator/Creator'
import './openLetter.css'
import OpenLetterHero from './OpenLetterHero/OpenLetterHero'
import OwnerShip from './OwnerShip/OwnerShip'
import RoadMap from './RoadMap/RoadMap'
import SignatureList from './SignatureList/SignatureList'
import { useLocation } from 'react-router-dom'

const OpenLetter = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        let removePercent = id.replace('%20', ' ')
        const element = document.getElementById(removePercent);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, []);


  return (
    <div className='opnLtContaienrMain'>
        <OpenLetterHero />
        <RoadMap />
        <OwnerShip />
        <Creator />
        <SignatureList />
        <Footer />
    </div>
  )
}

export default OpenLetter