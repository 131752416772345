import React from 'react'
import {BrowserRouter  as Router , Route, Routes } from 'react-router-dom'
import Home from '../Pages/Home/Index'
import OpenLetter from '../Pages/OpenLetter'
import TheMultiverse from '../Pages/TheMultiverse'

const AppRouter = () => {
  return (
    <Router>
        <Routes>
            <Route path='/' element={<Home />}  />
            <Route path='/open-letter' element={<OpenLetter />}  />
            <Route path='/multiverse' element={<TheMultiverse />}  />
        </Routes>
    </Router>
  )
}

export default AppRouter