import React from 'react'
import Button from '../Button/Button'
// import AlertDialog from '../Dialog/Dialog'
import { useNavigate } from 'react-router-dom'
import './style.css'

const GreenBox = ({ textOne, textTwo }) => {
  let navigate = useNavigate()
  // const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    // setOpen(true)
    navigate('/open-letter#Join us')
  }

  // const handleClose = () => {
  //   setOpen(false)
  // }
  return (
    <div className="grnBox">
      <div className="gnbx">
        <div className="gnSec">
          <div className="gnbxCont">
            <p className="mB20">
              {textOne} <br /> {textTwo}
            </p>
            {/* <AlertDialog isOpen={open} handleClose={handleClose} /> */}
            <Button text={'Sign Open Letter'} onClick={handleClickOpen} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GreenBox
