import React from 'react'
import Header from '../../../Components/Header/Header'
import line from '../../../assets/dashLine.svg'
import ScrollLinks from '../../Home/ScrollLinks'
import dotImg from '../../../assets/dotBg.png'

let links = ['Creators','Flywheel','Values','Join us']
let hrefData = ['Creators','Flywheel','Values','Join']

const OpenLetterHero = () => {
  return (
    <div className="opLtContainer">
      <img src={dotImg} className='dotImg' alt="..." />
      <Header />
      <div className="heroSection">
        <ScrollLinks hrefTag={hrefData} links={links} />
        <div className="heroContent">
          <div className="heroBox">
            <div className="hrSc opLtHrSc">
              <div className="heroLine opLHr">
                <p>Creators are</p>
                <p className="swaped">
                  <strong>hard-wired</strong>
                </p>
              </div>
              <p>to tell stories and</p>

              <div className="heroLine opLHr">
                <p>communities are</p>
                <p className="swaped">
                  <strong>hard-wired</strong>
                </p>
              </div>
              <p>to spread them</p>
            </div>
            <div className="tipsSec opLtHrTxt">
              <div className="tipsPara csJustifyCenter">
                <p className='opLHrHed'> 
                  {/* <img src={star} alt="star" /> */}
                  <span>Tokens</span> can superpower both!
                </p>
              </div>
            </div>
            <div className="dhLne">
              <img src={line} alt="line" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OpenLetterHero
