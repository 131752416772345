import React, { useEffect, useState } from 'react'
import line from '../../../assets/LineGrn.png'
import { database } from '../../../firebase/FirebaseConfig'
import { ref, onValue, query, limitToFirst } from 'firebase/database'
import Button from '../../../Components/Button/Button'
import AlertDialog from '../../../Components/Dialog/Dialog'

const SignatureList = () => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    // setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  let [signatureList, setSignatureList] = useState([])
  let [totalSigned, setTotalSigned] = useState([])

  let listArray = []
  const getData = async (limitDataVal) => {
    let data = query(ref(database, 'signature/'), limitToFirst(limitDataVal))
    onValue(data, (snapshot) => {
      listArray = []
      snapshot.forEach((res) => {
        let result = res.val()
        listArray.push(result)
      })

      setSignatureList(listArray)
    })
    let findTotalNumItems = ref(database, 'signature/')
    onValue(findTotalNumItems, (snap) => {
      let listSize = snap.size
      setTotalSigned(Array.from(listSize.toString()))
    })
  }

  useEffect(() => {
    getData(3)
  }, [])
  // console.log(signatureList)

  const handleMoreData = () => {
    getData(signatureList.length + 5)
  }

  return (
    <div id="Join us">
      <div>
        <div>
          <div className="counterBox">
            <p>
              There isn’t a stronger connection <br /> between people than
              storytelling!
            </p>
            <span>A new era of web3 stories starts here!</span>

            <div className="counter">
              {totalSigned.length &&
                totalSigned.map((val, i) => (
                  <div className="counts" key={val + i}>
                    {/* <h3>{val}</h3> */}
                    <h3>0</h3>
                    <p className="lineBlack"></p>
                  </div>
                ))}
            </div>
          </div>
          <br />
          <div className="crtBtn">
            <AlertDialog isOpen={open} handleClose={handleClose} />
            <Button
              // text="Sign Open Letter"
              text='Coming Soon'
              isGreen={true}
              onClick={handleClickOpen}
            />
          </div>
          {/* <div className="crtHead">
            <p>Embark Now!</p>
          </div>
          <div className="sgnListBox">
            <div className="sgnList">
              {signatureList.length &&
                signatureList.map((val, i) => {
                  return (
                    <div className="sgnLi mT20" key={i}>
                      {val.userName ? (
                        <>
                          <div className="gnLiHead csRow csJustifyBetween csAlignCenter">
                            <div className="sgnNm csRow csAlignCenter">
                              <p className="crcle"></p>
                              <div className="nmTag">
                                <p className="sgName mL20">{val.userName}</p>
                                <p className="tgMob">@{val.userName}</p>
                              </div>
                            </div>
                            <div className="sgnTag">
                              <a
                                href={`https://twitter.com/${val.userName}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                @{val.userName}
                              </a>
                            </div>
                          </div>
                          <div className="sgnAdrs mT20 mB20">
                            <p>{val.walletAddress}</p>
                            <p className="mT10">{val.time}</p>
                          </div>
                          <img src={line} alt="..." />
                        </>
                      ) : null}
                    </div>
                  )
                })}
              {signatureList.length > 0 ? (
                <div className="mrDta">
                  <button onClick={handleMoreData}>Show more Signatures</button>
                </div>
              ) : null}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default SignatureList
