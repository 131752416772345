import React, { useState } from 'react'
import blue from '../../../assets/blueImg.png'
import Button from '../../../Components/Button/Button'
import dao from '../../../assets/dao.png'
import star from '../../../assets/star.png'
import lineUp from '../../../assets/grenCurve.png'
import lineDn from '../../../assets/grenCurve2.png'
import GreenBox from '../../../Components/GreenBox/GreenBox'
import CardDialog from '../CardAlert'

const CardDetail = () => {
  let [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleCLost = () => {
    setOpen(false)
  }

  return (
    <div className="cdlContainer">
      <div className="cdShadGrnOne"></div>
      <div className="cdShadGryOne"></div>
      <div className="cdlSect">
        <div className="cdlContent">
          <div className="dlCards csRow csJustifyBetween csCenter">
            <div className="dlCard ">
              <div className="blurImgCard">
                <img src={blue} alt="" />
                <p>
                  Imagine if X-Files or Jurrassic Park had started on-chain with
                  NFT characters, and NFT bestiary. And that not only you could
                  own them, but have the opportunity to create your own
                  character and join the universe as an official Crypto-Z Agent!
                </p>
              </div>
              <Button isGreen={true} text="Discover" onClick={handleOpen} />
            </div>
            <CardDialog isOpen={open} handleClose={handleCLost} />

            <div className="dlCard ">
              <div className="blurImgCard">
                <img src={dao} alt="" />
                <p>
                  StingyDAO is a token-gated community that awards the infamous
                  “Stingy Awards”. <br /> <br /> Inspired by “Stingy Jack”, a
                  community governed character, StingyDAO share (mostly true)
                  stories about web3 from the lens of creators!
                </p>
              </div>
              <Button isGreen={true} text="Coming Soon" />
            </div>
          </div>
        </div>
        <div className="ringPara">
          <div className="rngShadGrn"></div>
          <img className="lineUp" src={lineUp} alt="line" />
          <div className="rPlist">
            <div className="rpHead">
              <span>INTEROPEABLE </span>
              <p>Tokenized Worlds</p>
            </div>
            <div className="tipsPara">
              <p>
                <img src={star} alt="star" /> The multiverse is the next-stage
                evolution of Tell It–Together, a network of worlds, showrunners,
                and communities sufficiently centralized to be interoperable.
              </p>
            </div>
            <div className="tipsPara">
              <p>
                <img src={star} alt="star" /> Each world or IP is governed by a
                sub-DAO led by their showrunners. Each sub-DAO is a part of the
                Tell It–Together network and votes on what comes next.
              </p>
            </div>
            <div className="tipsPara">
              <p>
                <img src={star} alt="star" /> Tell It–Together could receive 20%
                of tokens of each world to invest in cross-over experiences,
                creator tools, accelerator programs, and new IPs. Tell
                It-Together provides a positive sum ecosystem for creators
                designed to constantly improve itself.
              </p>
            </div>
          </div>
          <img className="lineDown" src={lineDn} alt="line" />
        </div>
        <div className="bgngSect">
          <div className="cdShadGrnOne"></div>
          <div className="bgngContent">
            <div className="bgngHd">
              <p>This movement is just the beginning...</p>
            </div>
            <br /> <br />
            <GreenBox
              textOne="Would you like to join us "
              textTwo=" in imagining that world?"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardDetail
