import React from 'react'
import logo from '../../assets/logo.svg'
import twitter from '../../assets/twitter.png'
import flower from '../../assets/flower.png'
import discord from '../../assets/discord.png'
import tMark from '../../assets/t_mark.png'
import './Footer.css'

const Footer = () => {
  return (
    <div className="ftContainer">
      <div className="ftSect">
        <div className="ftContent">
          <div className="ftdtls">
            <div className="ftLogo">
              <img src={logo} alt="logo" />
            </div>
            <div className="ftScLi">
              <a href="http://" target="_blank" rel="noopener noreferrer">
                <img src={twitter} alt="twiter" />
              </a>
              <a href="http://" target="_blank" rel="noopener noreferrer">
                <img src={flower} alt="twiter" />
              </a>
              <a href="http://" target="_blank" rel="noopener noreferrer">
                <img src={discord} alt="twiter" />
              </a>
            </div>
            <div className="tmark">
              <img src={tMark} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
