import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import CustomButton from '../Button/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Close } from '@mui/icons-material'
import fox from '../../assets/fox.png'
import { verifyMessage } from 'ethers/lib/utils'
import { ethers } from 'ethers'
import { database } from '../../firebase/FirebaseConfig'
// import {ref , push }  from 'firebase'
import { ref, push, onValue } from 'firebase/database'
import lines from '../../assets/rdph2.png'
import twostar from '../../assets/stars2.png'
import twostar2 from '../../assets/stars.png'     
import { useConnect, useSignMessage, useAccount } from 'wagmi'
import axios from 'axios'

import './dialog.css'
import ActionAlerts from '../Alert/Alert'

export default function AlertDialog({ handleOpen, isOpen, handleClose }) {
  const {
    connect,
    connectors,
    error,
    isLoading,
    pendingConnector,
  } = useConnect()
  let { address } = useAccount()
  let [walletAddress, setAddress] = React.useState('')
  let [errorMsg, setErrorMsg] = useState(null)
  let [signatures, setSignature] = useState(null)
  let [verifyMsg, setVerifyMsg] = useState(null)
  let [userSignature, setUserSignature] = useState(null)
  let [isShareTwitter, setIsShareTwitter] = useState(false)
  let [twitUserName, setUserName] = useState(null)
  let [twitUrl, setUrl] = useState('')
  let [showAlert, setAlert] = useState({
    status: '',
    message: '',
    show: false,
  })

  let [signatureList, setSignatureList] = useState([])

  useEffect(() => {
    const getData = async () => {
      let listArray = []
      let data = ref(database, 'signature/')
      onValue(data, (snapshot) => {
        listArray = []
        snapshot.forEach((res) => {
          let result = res.val()
          listArray.push(result)
        })

        setSignatureList(listArray)
      })
    }
    getData()
  }, [])

  let signMsg = `We are all storytellers living in an intertwined network of stories. Just like protocols, stories should be owned by communities and survive their creators.\n 
  Together, let’s make a future where storytellers maintain ownership over the value they generate and communities ownership over the stories they love!`

  // let twitterMessage = `Creators are hard-wired to tell stories, and communities are hard-wired to spread them. Let’s build a future where storytellers maintain ownership over the value they generate and communities ownership over the stories they love!  Let’s`
  let twitterMessageTwo = `Let’s build a future where storytellers maintain ownership over the value they generate and communities ownership over the stories they love! 
  Let’s www.tellittogether.com @cryptozagency #story3`

  const handleWalletConnect = async () => {
    if (window.ethereum) {
      let provider = new ethers.providers.Web3Provider(window.ethereum)
      let reqst = await window.ethereum.request({
        method: 'eth_requestAccounts',
      })
      setAddress(reqst[0])
      const signer = provider.getSigner()
      const signature = await signer.signMessage(signMsg)
      const verifySignature = await verifyMessage(signMsg, signature)
      let checkThatAlreadysigned = signatureList.filter(
        (val) => val.walletAddress === reqst[0],
      )

      // check that wallet already signed in or not
      if (!checkThatAlreadysigned.length > 0) {
        if ((await signer.getAddress()) === verifySignature) {
          setSignature(signature)
          // console.log(signature)
        }
      } else {
        handleClose()
      }
      // console.log('verification', verifySignature)
    } else {
      alert('Please Install metamask')
    }
  }

  // share signature on twitter
  const shareSignature = () => {
    let twit = `https://twitter.com/intent/tweet?text=${signatures.slice(
      0,
      16,
    )} \n ${twitterMessageTwo}`
    // window.location.href = `https://twitter.com/intent/tweet?text=My Signature: ${signatures}`
    window.open(twit, '_blank')
    setIsShareTwitter(true)
  }

  const handleUrl = (e) => {
    setUrl(e.target.value)
  }

  // verify twit and send data on firebase database
  const handleVerifyTwit = () => {
    // check that user paste twit url or not
    if (twitUrl.length > 0) {
      let getIndexForId = twitUrl.lastIndexOf('status/')
      let IdOFTweet = twitUrl.slice(getIndexForId + 7, twitUrl.length)
      if (
        twitUrl.includes('https://twitter.com/') &&
        twitUrl.includes('status')
      ) {
        axios({
          method: 'get',
          // url: `https://twitter-project.herokuapp.com/getTweet/${IdOFTweet}`,
          url: `https://twitter-api-red.vercel.app/getTweet/${IdOFTweet}`,
        })
          .then((res) => {
            // fetchTweet(String(IdOFTweet))
            // check that user paste valid twit url or paste another web url
            let tweetData = res.data
            console.log(tweetData.data)
            // console.log(walletAddress)
            if (tweetData.data?.text.includes(signatures.slice(0, 16))) {
              let findStatusIndex = twitUrl.indexOf('/status')
              let findUserName = twitUrl.slice(20, findStatusIndex)
              // alert('valid url')
              push(ref(database, 'signature/'), {
                userName: findUserName,
                time: new Date().toLocaleString(),
                signature: signatures,
                walletAddress: walletAddress,
              }).then((res) => {
                setSignature(null)
                setUrl('')
                setIsShareTwitter(false)
                handleClose()
                // console.log(res)
              })
              setUserName(findUserName)

              // console.log(findUserName, findStatusIndex)
            } else {
              // alert("Please Enter Valid tweet Link Thanks!");
              // setErrorMsg('Please Enter Valid tweet Link Thanks!')
              setAlert({
                status: 'error',
                message:
                  'Oops! Please copy paste the URL of the tweet you just shared!',
                show: true,
              })
              handleAutoCloseAlert()
            }
          })
          .catch((error) => {
            console.log(error)
            // alert('Something went wrong please try again later!')
            setAlert({
              status: 'error',
              message: 'Something went wrong please try again later!',
              show: true,
            })
            handleAutoCloseAlert()
          })
      } else {
        // alert("please enter valid twitter url to verify");
        setAlert({
          status: 'error',
          message:
            'Oops! Please copy paste the URL of the tweet you just shared!',
          show: true,
        })
        handleAutoCloseAlert()
      }
    } else {
      // alert("Please Enter tweet url to verify");
      setAlert({
        status: 'error',
        message:
          'Oops! Please copy paste the URL of the tweet you just shared!',
        show: true,
      })
      handleAutoCloseAlert()
    }
  }

  const signInWithoutVerify = () => {
    push(ref(database, 'signature/'), {
      userName: null,
      time: new Date().toLocaleString(),
      signature: signatures,
      walletAddress: walletAddress,
    })
      .then((res) => {
        setSignature(null)
        setIsShareTwitter(false)
        handleClose()
        // console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const { data, signMessage } = useSignMessage({
    onSuccess(data, variables) {
      // Verify signature when sign message succeeds
      const addressVerify = verifyMessage(variables.message, data)
      setVerifyMsg(addressVerify)
      setUserSignature(data)
      let checkThatAlreadysigned = signatureList.filter(
        (val) => val.walletAddress === address,
      )

      // check that wallet already signed in or not
      if (!checkThatAlreadysigned.length > 0) {
        if (address === addressVerify) {
          setSignature(data)
          // console.log(signature)
        }
      } else {
        handleClose()
      }
    },
  })

  const handleMobileConnect = async (connector) => {
    connect({ connector })
    setAddress(address)
    await handleSingin()
  }
  const handleSingin = async () => {
    signMessage({ message: signMsg })
  }
  const handleCloseAlert = () => {
    setAlert({ show: false })
  }

  const handleAutoCloseAlert = () => {
    setTimeout(() => {
      setAlert({ show: false })
    }, 1500)
  }

  return (
    <>
      {showAlert.show ? (
        <ActionAlerts
          onClick={handleCloseAlert}
          status={showAlert.status}
          message={showAlert.message}
        />
      ) : null}
      <div className="walletModal">
        {/* <AccountButton /> */}
        <Dialog
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="dialogContainer"
          disableEscapeKeyDown={false}
          hideBackdrop
        >
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              <Close className="whtIcon" />
            </Button>
          </DialogActions>
          <DialogTitle id="alert-dialog-title" className="dialogHead">
            {!signatures && !isShareTwitter
              ? 'Connect  your wallet.'
              : !isShareTwitter && signatures
              ? 'Verify Your Signature'
              : 'Copy the URL of your Tweet'}
          </DialogTitle>
          <DialogContent>
            <p className="dialogPara">
              {!signatures && !isShareTwitter
                ? `Connect with one of our available wallet providers or create a new
            one.`
                : !isShareTwitter && signatures
                ? `Tweet this message to prove that you control this wallet.
              Return back here afterwards to post the tweet URL. `
                : 'Copy paste the URL of your tweet to prove it’s you (no bots allowed)!'}
            </p>
            {!signatures && !isShareTwitter ? (
              <>
                <button className="cnctBtn" disabled onClick={handleWalletConnect}>
                  <div>
                    {/* <img src={fox} alt="fox" /> */}
                    {/* <p>MetaMask</p> */}
                    <p>Coming soon</p>
                  </div>
                </button>
                {connectors.map((connector) => (
                  <button
                    disabled={!connector.ready}
                    key={connector.id}
                    onClick={() => handleMobileConnect(connector)}
                    className="connectorBtns"
                  >
                    {connector.name}
                    {!connector.ready && ' (unsupported)'}
                    {isLoading &&
                      connector.id === pendingConnector?.id &&
                      ' (connecting)'}
                  </button>
                ))}

              </>
            ) : signatures && !isShareTwitter ? (
              <div className="shreBtn">
                {/* <Button onClick={shareSignature}>Share On Twitter</Button> */}
                <CustomButton
                  text={'POST PROOF ON TWITTER'}
                  isGreen
                  onClick={shareSignature}
                ></CustomButton>
                <img src={lines} alt="..." />
                <button className="linkcsBtn" onClick={signInWithoutVerify}>
                  Sign without verifying
                </button>
                <p className="smParaDialog">
                  (but it won`t be registered for whatever comes next....)
                </p>
              </div>
            ) : (
              <div className="vrfyUrlBox">
                <input
                  type="url"
                  name="url"
                  id="url"
                  onChange={handleUrl}
                  value={twitUrl}
                  placeholder="https://twitter.com/username/status/1593310167302606848"
                />
                <div className="verifyBtn">
                  <img src={twostar} alt="stars" />
                  <CustomButton
                    isGreen
                    text={'Verify Url'}
                    onClick={handleVerifyTwit}
                  ></CustomButton>
                  <img src={twostar2} alt="stars" />
                </div>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </div>
    </>
  )
}
