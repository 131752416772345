import React, { useEffect } from 'react'
import FAQ from './Faq/Faq'
import Hero from './Hero/Hero'
import './home.css'
import StoryTelling from './StoryTelling/StoryTelling'
import Footer from '../../Components/Footer/Footer'
import dotImg from '../../assets/dotBg.png'

const Home = () => {


  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <div className="homeContainer">
      {/* <img src={dotImg} className='dtImgHm' alt="bg" /> */}
      <Hero id="Top" />
      <StoryTelling />
      <FAQ />
      <Footer />
    </div>
  )
}

export default Home
