import React from 'react'
import Header from '../../../Components/Header/Header'
import star from '../../../assets/star.png'
import dotImg from '../../../assets/dotBg.png'

const Multiverse = () => {
  return (
    <div className="multiContainer">
      <img src={dotImg} className='dotImg' alt="" />
      <Header />
      <div className="mlVrseSection">
        <div className="mlVerseConte">
          <div className="mlVrseHead">
            <p> Travel Through the Multiverse </p>
          </div>
          <div className="multiTreeBox">
            <div className="breakLine">
              <p className="brkSm"></p>
              <p className="brkMd"></p>
              <p className="brkLg"></p>
            </div>
            <div className="multiTreeSummry">
              <div className="mlTreeOne">
                <p>
                  <img src={star} alt="star" />
                 {' '} Audio drama
                </p>
                <p>
                  Stories & Characters {' '}
                  <img src={star} alt="star" />
                </p>
              </div>
              <div className="mlTreeTwo">
                <p>
                  <img src={star} alt="star" />{' '}
                  comics & Manga
                </p>
                <p>
                Awards & Events{' '}
                  <img src={star} alt="star" />
                </p>
              </div>
              <div className="mlTreethre">
                <p>
                  <img src={star} alt="star" />{' '}
                  Game
                </p>
                <p>
                Film & Anime{' '}
                  <img src={star} alt="star" />
                </p>
              </div>
              <div className="mtTreFinl">
                <p>
                  <img src={star} alt="star" />{' '}
                  Metaverse
                  {' '} <img src={star} alt="star" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Multiverse
