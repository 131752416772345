import React from 'react'
import GreenBox from '../../../Components/GreenBox/GreenBox'
import star from '../../../assets/star.png'
import kernel from '../../../assets/kernel.svg'
import logo2 from '../../../assets/logo2.png'
import logo3 from '../../../assets/logo3.png'
import club from '../../../assets/club.png'
import gitCoin from '../../../assets/gitCoin.png'

const StoryTelling = () => {
  return (
    <div className="stgContainer" id="Story">
      <div className="grnBoxContainer">
        <GreenBox textOne='Would you like to imagine ' 
         textTwo='that world with us?'
        />
      </div>
      <div className="stgContent">
        <div className="stgSection">
          <div className="stgDetails">
            <h3 className="stgHead mB20">A new Era for Web3 stories </h3>
            <div className="stgLines">
              <div className="tipsPara">
                <p>
                  <img src={star} alt="star" />{' '}
                  Authors have learned to tell stories through books, plays,
                  films, comics, and games for ages. Now, they must reinvent
                  their art, except this time, holders are at the center of the
                  story!
                </p>
              </div>
              <div className="tipsPara">
                <p>
                  <img src={star} alt="star" />{' '} Tell it–Together is where
                  creators can learn, experience, create, and collaborate over
                  community-owned stories.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="advsBox">
        <div className="advsCont">
          <div className="advsSec">
            <p>
              We build with advisors <br />
              from the best in web3
            </p>
            <div className="advsLogos mT20">
              <img src={kernel} alt="kernel" />
              <img src={logo2} alt="logo" />
              <img src={logo3} alt="logo" />
              <img src={club} alt="club" />
            </div>
            <div className="advsLgMob">
              <div className="advsSmLg">
                <img src={logo2} alt="logo" />
                <img src={logo3} alt="logo" />
              </div>
              <div className="advsLgLog">
                <img src={kernel} alt="kernel" />
                <img src={club} alt="club" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="advsBox">
        <div className="grnBox">
          <div className="gnbx">
            <div className="gnSec">
              <div className="advsSec">
                <p className="mB20">
                  We build with advisors <br />
                  from the best in web3
                </p>
              </div>
              <div className="gtCn">
                <img src={gitCoin} alt="coin" />
                <img src={gitCoin} alt="coin" />
                <img src={gitCoin} alt="coin" />
                <img src={gitCoin} alt="coin" />
                <img src={gitCoin} alt="coin" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default StoryTelling
