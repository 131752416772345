import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import CustomButton from '../../Components/Button/Button'
import { Close } from '@mui/icons-material'
import stars from '../../assets/grey-stars.png'
import apple from '../../assets/apple.png'
import google from '../../assets/google-podcast.png'
import spotify from '../../assets/spotify.png'

export default function CardDialog({ handleOpen, isOpen, handleClose }) {
  const [open, setOpen] = React.useState(true)

  const handleClickOpen = () => {
    setOpen(true)
  }
  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  const handleWebsite = () => {
    window.open('https://www.crypto-z.com', '_blank')
  }
  const handleDiscord = () => {
    window.open('https://discord.gg/BRcMkdf4br', '_blank')
  }
  const handleTwitter = () => {
    window.open('', '_blank')
  }

  return (
    <div className="walletModal cardMetaDialog">
      {/* <AccountButton /> */}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialogContainer dgMeta"
        disableEscapeKeyDown={false}
        hideBackdrop
      >
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            <Close className="whtIcon" />
          </Button>
        </DialogActions>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ marginTop: '60px', marginBottom: '20px' }}
          className="dialogHeadMeta"
        >
          Discover the world of Crypto-Z
        </DialogTitle>
        <DialogContent>
          <div className="linksBtns">
            <CustomButton
              isGreen
              text={'Website'}
              onClick={handleWebsite}
            ></CustomButton>
            <br />
            <CustomButton
              isGreen
              text={'Discord'}
              onClick={handleDiscord}
            ></CustomButton>
            <br />
            <CustomButton
              isGreen
              text={'Twitter'}
              // onClick={handleVerifyTwit}
            ></CustomButton>
          </div>
          <div className="metaDgIcons">
            <a href="https://open.spotify.com/show/4C1BI786MlUJZoAdmJ3wQt" target="_blank" rel="noopener noreferrer">
              <img src={spotify} alt="spotify" />
            </a>
            <a href="https://podcasts.apple.com/us/podcast/crypto-z/id1503388186" target="_blank" rel="noopener noreferrer">
              <img src={apple} alt="apple" />
            </a>
            <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9ldXBob25pZS5tZWRpYS9mZWVkL3BvZGNhc3Qv" target="_blank" rel="noopener noreferrer">
              <img src={google} alt="google" />
            </a>
          </div>
          <p className="dialogParaMeta">Listen to the show</p>
          <img src={stars} className="grStars" alt="" />
        </DialogContent>
      </Dialog>
    </div>
  )
}
