import React, { useEffect } from 'react'
import multiLine from '../../../assets/multiLines.svg'
import ownerLine from '../../../assets/ownerLine.svg'
import star from '../../../assets/twoStar.svg'
import circle from '../../../assets/circleWithText.svg'
import rockets from '../../../assets/rockets.png'
import book from '../../../assets/book.svg'
import animals from '../../../assets/animalsWithLines.svg'
import AOS from 'aos'
import 'aos/dist/aos.css'

const OwnerShip = () => {
  useEffect(() => {
    AOS.init()
  }, [])
  return (
    <div id="Flywheel">
      <div>
        <div>
          <div >
            <div className="onrHead">
              <img
                className="onrStrImg"
                data-aos="zoom-in"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="true"
                src={star}
                alt=""
              />
              <p>
                {' '}
                <span className="mltiLineSpn">
                  {' '}
                  Ownership{' '}
                  <svg
                    width="291"
                    className="multiLines"
                    height="106"
                    viewBox="0 0 291 106"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke="#ABFF29"
                      strokeWidth={2}
                      d="M123.445 45.6657C109.79 46.175 96.2954 46.8014 83.1357 47.4861C64.5067 48.4547 45.8849 49.4567 27.3753 51.0433C21.5489 51.5443 15.6321 51.8616 9.84216 52.4878C6.20758 52.8803 1.38821 53.4397 0.755732 53.5566C0.424918 53.6234 0.280595 53.7237 0.23396 53.7571C-0.0939389 53.9909 -0.0341094 54.2163 0.150972 54.3916C0.225295 54.4668 0.413238 54.6505 0.934963 54.6756C35.8264 56.3874 71.5048 53.0473 106.438 52.6966C167.02 52.0954 229.437 54.4835 289.537 59.6941C290.062 59.7358 290.572 59.527 290.645 59.2181C290.732 58.9174 290.354 58.6252 289.829 58.5834C229.627 53.3645 167.107 50.968 106.409 51.5776C73.8525 51.9033 40.653 54.8342 8.0511 53.8322C8.80017 53.7487 9.5347 53.6652 10.2051 53.59C15.9717 52.9638 21.8637 52.6548 27.6668 52.1538C46.137 50.5673 64.7209 49.5652 83.3252 48.605C106.409 47.4025 130.499 46.3671 154.778 45.8828C163.464 45.9579 172.12 46.0331 180.777 46.125C199.503 46.3254 218.318 46.9099 237.001 47.6948C242.626 47.937 248.251 48.1875 253.877 48.4046C255.742 48.4797 260.551 48.6968 261.221 48.6801C262.052 48.6634 262.212 48.2626 262.227 48.1958C262.271 48.0455 262.242 47.8367 261.833 47.6614C261.79 47.6363 261.527 47.5528 260.945 47.5027C226.974 44.5467 190.701 44.0541 154.807 44.7639C116.946 44.4549 78.9386 44.3213 41.1733 44.1793C40.6327 44.1793 40.1911 44.4299 40.1867 44.7388C40.1838 45.0478 40.6195 45.2983 41.1602 45.3067C68.5055 45.4069 95.9893 45.507 123.445 45.6657Z"
                      fill="#111111"
                    />
                  </svg>{' '}
                </span>{' '}
                Is The New <br /> Entertainment{' '}
              </p>
              <img
                className="onrLIne"
                data-aos="zoom-in"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="true"
                src={ownerLine}
                alt=""
              />
            </div>
            <div className="onrspDetails">
              <div className="ownrSpBox">
                <div className="ownrROtation">
                  <img src={circle} className="crcleImg" alt="circle" />
                  <img src={rockets} className="rckImg" alt="rockets" />
                </div>
              </div>
            </div>
          </div>
          <div className="bkSection" id="Fiction">
            <div className="bkDetls">
              <div className="bkHead">
                <p>
                  “Beyond the fiction of reality, there is the reality of the
                  fiction.”{' '}
                </p>
                <span>-Salvoj Žižek</span>
              </div>
              <div className="bkTxt">
                <p>
                  Traditionally, writers conceive of great stories in isolation
                  with little outside participation. Creators can benefit from
                  designing stories with collective participation in mind.
                  Narratives, characters, and plot twists will remain the
                  creator’s privilege. No one likes spoilers! Participation is
                  not asking for permission from your community. It’s inviting
                  them onto the stage.
                </p>
              </div>
            </div>
            <div className="bkBox">
              <div className="bkImg">
                <img
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-once="true"
                  data-aos-anchor-placement="center-bottom"
                  src={animals}
                  className="anmlsImg"
                  alt=""
                />
                <img src={book} className='bokImg' alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OwnerShip
