import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../../assets/logo.svg'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import './Header.css'

const Header = () => {
  let [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div className='headerContainer'>
      <div className="csHeader">
        <div className="headContent csRow csJustifyBetween">
          <div className="hdLogo">
            <NavLink to={'/'}>
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          <div className={open ? 'showMenu hdLinks' : 'hdLinks'}>
            <div className="mbMnHead">
              <img src={logo} alt="logo" />
              <button className="MnBtn" onClick={handleClose}>
                <CloseIcon className="mnIcon" />
              </button>
            </div>
            <div className="hdlis">
              <div className="hdli">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'activeLink hdLink mT10' : 'hdLink mT10'
                  }
                  onClick={handleClose}
                  to="/"
                >
                  Home
                </NavLink>
              </div>
              <div className="hdli">
                <NavLink
                  to="/open-letter"
                  className={({ isActive }) =>
                    isActive ? 'activeLink hdLink mT10' : 'hdLink mT10'
                  }
                  onClick={handleClose}
                >
                  Open Letter
                </NavLink>
              </div>
              <div className="hdli">
                <NavLink
                  to="/multiverse"
                  onClick={handleClose}
                  className={({ isActive }) =>
                    isActive ? 'activeLink hdLink mT10' : 'hdLink mT10'
                  }
                >
                  The Multiverse
                </NavLink>
              </div>
            </div>
          </div>
          <button className="MnBtn" onClick={handleOpen}>
            <MenuIcon className="mnIcon" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Header
