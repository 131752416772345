import './App.css'
import AppRouter from './Router/AppRouter'
import {
  WagmiConfig,
  createClient,
  defaultChains,
  configureChains,
  chain
} from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

const { chains, provider, webSocketProvider } = configureChains([chain.mainnet,chain.goerli,chain.polygon], [

  publicProvider(),
])

// Set up client
const client = createClient({
  autoConnect: false,
  connectors: [
    // new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
        infuraId:`https://mainnet.infura.io/v3/7d7b4baf408a4c53a6058f0cafc0e637`,

      },
    }),
  ],
  provider,
  webSocketProvider,
})

function App() {

  // useEffect(()=>{
  //    dotenv.config()
  // },[])

  return (
    <div className="App">
      <WagmiConfig client={client}>
        <AppRouter />
      </WagmiConfig>
    </div>
  )
}

export default App
